(function ($) {

  $.fn.fUpload = function (source, options) {

    const settings = $.extend({
      'readonly':   false,
    }, options);

    const id = this.attr('id');

    let str = '';
    if (settings.readonly === false) {
      str = '<i class=\'removeAtt pull-right fa fa-trash-o\' class=\'pointer-cursor\'></i>';
    }

    for (let i = 0; i < source.length; i++) {
      this.append(
        '<div class=\'alert alert-success\' class=\'success-alert\' data-id=\'' + source[i].id + '\' id=\'c' + id + source[i].id + '\'>' +
                   '<a class=\'downloadAtt\' class=\'white\' target=\'_blank\' href=\'' + source[i].href + '\'><i class=\'fa fa-download\'></i> ' + source[i].name + '</a>' +
                   str +
                '</div>'
      );
    }

    if (settings.readonly === false) {

      this.append('<input type=\'file\' name=\'' + id + '[0]\' id=\'' + id + '0\'/>');

      let i = 1;
      this.delegate('input', 'change', function () {
        const obj = $(this);

        obj.hide();
        obj.after('<input type=\'file\' name=\'' + id + '[' + i + ']\' id=\'' + id + i + '\' />');
        obj.after(
          '<div class=\'alert alert-success\' class=\'success-alert\' id=\'c' + obj.attr('id') + '\'>' +
                        obj.val().split('\\').pop() + '<i class=\'fa fa-trash cancelAtt pull-right\' class=\'pointer-cursor\'></i>' +
                    '</div>'
        );
        obj.appendTo('#c' + obj.attr('id'));

        i++;
      });

      this.delegate('.removeAtt', 'click', function () {
        const $parent = $(this).parent();

        $parent.after('<input type=\'hidden\' name=\'removeAtt[' + $parent.data('id') + ']\' />');
        $parent.hide(500);
      });

      this.delegate('.cancelAtt', 'click', function () {
        const $parent = $(this).parent();

        $parent.hide(500, function () { $(this).remove(); });
      });
    }
  };

})(jQuery);
