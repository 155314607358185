/**
 * Hilfsfunktionen
 *
 * @depends on jquery.js, jquery.scrollTo.js
 * @author Martin Bieder <bieder@kdo.de>, Lion Cassens <lion.cassens@kdo.de>
 */


/**
 * Zu eventuellen Form-Fehlern scrollen
 * @type {[type]}
 */
if (typeof $.scrollTo === 'function') {
    var errors = $('.control-group.has-error');
    if(errors.length > 0) {
        $.scrollTo(errors.eq(0), 1000);
    }
}

/**
 * Media Query Listener für Responsive Table
 */
if (window.matchMedia) {
    var mq = window.matchMedia("(max-width: 979px)");
    mq.addListener(widthChange);
    widthChange(mq);
}
function widthChange(mq) {
    if (mq.matches) {
        $('.table:not(.table-unresponsive)').each(function() {
            var head_col_count =  $(this).find('thead tr th').length;

            for (var i=0; i <= head_col_count; i++ )  {
                var head_col_label = $(this).find('thead tr th:nth-child('+ i +')').text();

                $(this).find('tr td:nth-child('+ i +')').attr('data-label', head_col_label);
            }
        });
    }
}

/**
 * Zurück-Buttons unterstützen
 */
$('.js-back-button').on('click', function(e) {
    e.preventDefault();

    window.history.back();
});

/**
 * Hilfsklasse zur Löschbestätigung
 * @return {[type]} [description]
 */
$('.confirm-delete').on('click', function() {
    var msg = $(this).attr('data-confirm-msg');
    if (typeof msg !== typeof undefined && msg !== false) {
        return confirm(msg);
    } else {
        return confirm('Sind Sie sicher, dass Sie diesen Eintrag löschen möchten?');
    }
});

/**
 * jquery.show Funktionalität für Bootstrap
 * @author Lion Cassens
 * @param  {int} [duration]         wird an jquery.show weitergegeben
 * @param  {function} [complete]    wird ausgeführt sobald show beendet ist
 */
jQuery.fn.bsShow = function(duration, complete) {
    if (this.hasClass('hide')) {
        this.hide()
            .removeClass("hide")
            .show(duration, complete);
    }

    return this;
};

/**
 * jquery.hide Funktionalität für Bootstrap
 * @author Lion Cassens
 * @param  {int} [duration]         wird an jquery.hide weitergegeben
 * @param  {function} [complete]    wird ausgeführt sobald hide beendet ist
 */
jQuery.fn.bsHide = function(duration, complete) {
    duration = typeof duration !== 'undefined' ? duration : 0;

    var el = this;

    this.hide(duration, function () {
        el.addClass("hide");

        if (typeof complete !== "undefined") {
            complete();
        }
    });

    return this;
};

/**
 * jquery.toggle Funktionalität für Bootstrap
 * @author Lion Cassens
 * @param  {int} [duration]         wird an jquery.toggle weitergegeben
 * @param  {function} [complete]    wird ausgeführt sobald toggle beendet ist
 */
jQuery.fn.bsToggle = function(duration, complete) {
    duration = typeof duration !== 'undefined' ? duration : 0;

    var hidden = this.hasClass("hide");

    if (hidden) {
        this.bsShow(duration, complete);
    } else {
        this.bsHide(duration, complete);
    }

    return this;
};
